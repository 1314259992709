import {React, useState, useEffect} from 'react'
import "../css/Services.css"
import "../css/Swal.css"
import Navbar from './Navbar'
import { motion } from "framer-motion"
import Model from "react-modal";
import Swal from "sweetalert2";
import { redBorder } from '../functions/Validator';
import { changeBorder } from '../functions/Validator';
import ReactGA from "react-ga";
import FAQ from "./FAQ_Services";
import "../css/Home.css";

import corporate01 from "../components/Services/Corporate/01.jpg";
import corporate02 from "../components/Services/Corporate/02.jpg";
import corporate03 from "../components/Sample/Corporate/bag.jpeg";
import corporate04 from "../components/Services/Corporate/04.jpg";
import corporate05 from "../components/Services/Corporate/05.jpg";
import corporate06 from "../components/Services/Corporate/06.jpg";

import school01 from "../components/Services/School/01.jpg";
import school02 from "../components/Services/School/02.jpg";
import school03 from "../components/Services/School/03.jpeg";
import school04 from "../components/Services/School/04.jpg";
import school05 from "../components/Services/School/05.jpg";
import school06 from "../components/Services/School/06.jpg";

import college01 from "../components/Services/College/01.jpg";
import college02 from "../components/Services/College/02.jpeg";
import college03 from "../components/Services/College/03.jpg";
import college04 from "../components/Services/College/04.jpg";
import college05 from "../components/Services/College/05.jpg";
import college06 from "../components/Services/College/06.jpg";

import society01 from "../components/Services/Society/01.jpeg";
import society02 from "../components/Services/Society/02.jpg";
import society03 from "../components/Services/Society/03.jpeg";
import society04 from "../components/Services/Society/04.jpg";
import society05 from "../components/Services/Society/05.jpeg";
import society06 from "../components/Services/Society/06.jpeg";

import mall01 from "../components/Sample/Window/06.jpeg";
import mall02 from "../components/Services/Mall/02.jpg";
import mall03 from "../components/Services/Mall/03.jpg";
import mall04 from "../components/Sample/Window/01.jpeg";
import mall05 from "../components/Services/Mall/05.jpg";
import mall06 from "../components/Sample/Window/04.jpeg";

import techpark01 from "../components/Services/TechPark/01.jpg";
import techpark02 from "../components/Services/TechPark/02.jpg";
import techpark03 from "../components/Services/TechPark/03.jpeg";
import techpark04 from "../components/Services/TechPark/04.jpg";
import techpark05 from "../components/Services/TechPark/05.jpg";
import techpark06 from "../components/Services/TechPark/06.jpg";

import marathon01 from "../components/Services/Marathon/01.jpg";
import marathon02 from "../components/Services/Marathon/02.jpg";
import marathon03 from "../components/Services/Marathon/03.jpg";
import marathon04 from "../components/Services/Marathon/04.jpg";
import marathon05 from "../components/Services/Marathon/05.jpg";
import marathon06 from "../components/Services/Marathon/06.jpg";

import SEO from '../components/SEO';

const Services = () => {

  //non interaction event
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  },[]);

  const [enquiryShow, setEnquiryShow] = useState(false);
  // form fields
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [budget, setBudget] = useState('');
  const [details, setDetails] = useState('');

  const serviceHandler = async(ev) =>{ 
    var status = 0;        
    ev.preventDefault();

    if (status==0){
      const isTextValid = /^[A-Za-z\s]{4,}$/.test(name);
      const isMailValid = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/.test(mail);
      const isNumberValid = /^[6-9][0-9]{9}$/.test(phone);
      const isBudgetValid = /^[1-9][0-9]{1,8}$/.test(budget);
      const isDetailsValid = /^[a-zA-Z\s0-9.-]+$/.test(details);
      
      // Check if the textbox has valid name or not
      const textBox = document.getElementById("name");
      if (!isTextValid){     
      redBorder(textBox)                
      Swal.fire({
          title: "Validation!",
          text: "Name Must have minimum 4 letters. You cannot use numbers or any other special characters with name.",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })               
      }else{
        changeBorder(textBox)
      }

      // check if the mail has valid mail address or not
      const mailBox = document.getElementById("email");
      if(!isMailValid){
        redBorder(mailBox) 
        Swal.fire({
          title: "Validation!",
          text: "Mail format is wrong. For Example: name@gmail.com",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })                           
      }else{
        changeBorder(mailBox)
      }          

      // check if the number box is valid or not
      const phoneBox = document.getElementById("phone");
      if(!isNumberValid){
        redBorder(phoneBox)
        Swal.fire({
          title: "Validation!",
          text: "Phone number should start from 6-9. It should have 10 numbers. No letters are allowed.",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })     
    }else{
        changeBorder(phoneBox)
      }

      // check if the budget box is valid or not
      const budgetBox = document.getElementById("budget");
      if(!isBudgetValid){
        redBorder(budgetBox)
        Swal.fire({
          title: "Validation!",
          text: "Letters and special characters are not allowed in budget.",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })    
      }else{
        changeBorder(budgetBox)
      }

      // check if the requirement details box is valid or not
      const detailsBox = document.getElementById("details");
      if(!isDetailsValid){
        redBorder(detailsBox)
        Swal.fire({
          title: "Validation!",
          text: "You cannot use special characters in requirement details.",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })
      }else{
        changeBorder(detailsBox)
      }
      
      if(isTextValid && isMailValid && isNumberValid && isBudgetValid && isDetailsValid){
        status=1;
      }
    }
    
    if(status==1){            
      try{
        const response = await fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/postsServQuery', {
        method:'POST',
        body: JSON.stringify({name,mail,phone,serviceName,budget,details}),
        headers:{
          "Content-Type": "application/json"
        },
        })
        if(response.status === 200){
          Swal.fire({
            title: "Services",
            text: "Enquiry Submitted!",
            icon: "success",
            customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
            }
          });
          setName('');
          setMail('');
          setPhone('');
          setServiceName('');
          setBudget('');
          setDetails('');
          setEnquiryShow(false);
          
        }else{
          Swal.fire({
            title: "Enquiry",
            text: response.status,//"Oops! Enquiry Failed.",
            icon: "warning",
            customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
            }
          });
        }
    }catch(e){
      Swal.fire({
        title: "Enquiry!",
        text: e,//"Error Ocurred. Try After Sometime",
        icon: "error",
        customClass: {
          confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
        }
      });
    }
  }
  }


  return (
    <>

<SEO
    title="Best Marketing and Advertising Company in Bangalore"
    description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
    keywords="college event,
    school events,
    society events
    rwa activities,
    atl activities,
    btl activities,
    atl and btl marketing,
    rwa society,
    techpark events,
    corporate events,
    "
  />

    <Navbar/>

    <motion.div className="container-fluid"
     initial={{ opacity: 0, y: "-100vh" }}
     animate={{ opacity: 1, y: 0 }}
     exit={{ opacity: 0, y: "-100vh" }}
     transition={{ duration: 0.5 }}
    >
            <div>
              {/* <input type="button" value="Open Model" onClick={()=>setEnquiryShow(true)}/> */}
              <Model
                isOpen={enquiryShow}
                className="enquiry-box-container centered-popup p-5"
              >
                {/* <div className="enquiry-box-container centered-popup"> */}
                <div className="enquiry-box">
                  <h2 className="text-center">Enquire</h2>
                  <i
                    class="fa fa-times text-white close-btn enquiry-close"
                    aria-hidden="true"
                    onClick={() => setEnquiryShow(false)}
                  ></i>
                  <form action="" onSubmit={serviceHandler}>
                    <div className="input">
                      <input 
                        type="text" 
                        name="name" 
                        placeholder="Name"
                        id="name"
                        required="true"
                        value={name}
                        onChange={ ev => setName(ev.target.value)}
                      />
                    </div>
                    <div className="input">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={mail}
                        id="email"
                        required="true"
                        onChange={ ev=>setMail(ev.target.value)}
                      />
                    </div>
                    <div className="input">
                      <input 
                        type="text" 
                        name="phone" 
                        placeholder="Phone Number"
                        value={phone}
                        id="phone"
                        required="true"
                        maxLength={10}
                        onChange={ ev=> setPhone(ev.target.value)}  
                      />
                    </div>
                    <div className="input">
                      <select name="service" id="" onChange={ ev => setServiceName(ev.target.value)} required="true">
                        <option value="Corporate Event">Corporate Events</option>
                        <option value="School Event">School Events</option>
                        <option value="College Event">College Event</option>
                        <option value="Society Event">Society Event</option>
                        <option value="Mall Event">Mall Event</option>
                        <option value="Tech Park Event">Tech Park Event</option>                        
                        <option value="Marathon Event">Marathon Event</option>                        
                      </select>
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        name="budget"
                        placeholder="Quote your budget in Rs."
                        value={budget}
                        required="true"
                        id="budget"
                        maxLength={5}
                        onChange={ ev => setBudget(ev.target.value)}
                      />
                    </div>
                    <div className="input" id="text-area">
                      <textarea
                        name="details"
                        id="details"
                        cols="30"
                        rows="1"
                        placeholder="Write all your requirements and queries.."
                        value={details}
                        onChange={ ev => setDetails(ev.target.value)}                        
                        required="true"
                      ></textarea>
                    </div>
                    <div className="input">
                      <input
                        type="submit"
                        value="Enquiry"
                        className="sub-but"
                      />
                    </div>
                  </form>
                </div>
                {/* </div> */}
              </Model>
            </div>
            

      <div className="service-container">

        <div className="services ad-containerWhite container-position">

        <div className='service-box'>
          <h2>
            Corporate Events
            <br />
            <small>
            Build relationships & strengthen your teams by conducting creative & fun activities!!
            </small>
          </h2>          
            <p className='service-description p' >
            We'll give you the greatest items to liven up your celebration.
            Do you already have a budget in mind?
            Your event will be planned in accordance with it.
            Are you considering introducing a new product during the event?
            We are able to sample products for you.
            We can assist you with banners and LED boards for your upcoming trade show.
            We have everything you need for your next corporate event, including canopies, stalls, flyers,
            selfie frames, media walls, badges, and personalised t-shirts.


            </p>
            <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
            />
        </div>

        

          <div className="service-pics">

              <div className="pics">
                <img src= {corporate01} alt="corporate event branding" />
                
              </div>
              
              <div className="pics">
                <img src={corporate02} alt="corporate event branding ideas" />
                
              </div>
              
              <div className="pics">
                <img src= {corporate03} alt="corporate event branding activaion" />
                
              </div>

              <div className="pics">
                <img src= {corporate04} alt="corporate event marketing" />
                
              </div>
              
              <div className="pics">
                <img src={corporate05} alt="corporate event design" />
                
              </div>
              
              <div className="pics">
                <img src= {corporate06} alt="corporate event branding products" />
                
              </div>

            <h2>
            Sample Work
          </h2>
          
              
          </div>

        </div>

        <div className="services ad-containerGrey container-position">
          
        <div className='service-box'>
          <h2>
            
            School Events
            <br />
            <small>
            Plan your next learning and off-learning school event with us!! 
            </small>
          </h2>          
            <p className='service-description p' >
            An exciting school event can boost student participation and persuade parents to send their
            children back the next year.
            We will create and help with all branding materials for a sizable interschool event that 
            will draw students from different areas.
            Consider giving personalized pens, bottles, and t-shirts as gifts.Reach out to us.
            You will receive banners, flyers, campaign posters, themed posters, and standees from us.

            </p>
            <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
            />
        </div>

          <div className="service-pics">
              <div className="pics">
                <img src= {school01} alt="school event branding" />
              </div>
              <div className="pics">
                <img src= {school02} alt="school event marketing agency" />
              </div>
              <div className="pics">
                <img src= {school03} alt="school event marketing agency" />
              </div>
              <div className="pics">
                <img src= {school04} alt="school branding examples" />
              </div>
              <div className="pics">
                <img src= {school05} alt="school branding ideas" />
              </div>
              <div className="pics">
                <img src= {school06} alt="school branding companies" />
              </div>

              <h2>
            Sample Work
          </h2>
              
          </div>

        </div>
        
        <div className="services ad-containerWhite container-position">
          
        <div className='service-box'>
          <h2>
            College Events
            <br />
            <small>
            Ecourage your students with fun & engaging events!! 
            </small>
          </h2>          
            <p className='service-description p' >
            To add excitement to your college event, we will supply you the greatest products.
            Do you have a certain budget in mind?
            In light of that, we will organize your event.
            We will assist with all the branding items for a large intercollegiate event that
            will draw students from many regions.
            We can provide canopies, booths, flyers, social media frames,customised t-shirts, 
            bottles, college bags, pens, caps, and more for your upcoming college event. 
            </p>
            <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
            />
        </div>

          <div className="service-pics">
          <div className="pics">
                <img src= {college01} alt="college event branding" />
              </div>
              <div className="pics">
                <img src= {college02} alt="college event logo" />
              </div>
              <div className="pics">
                <img src= {college03} alt="college event branding products" />
              </div>
              <div className="pics">
                <img src= {college04} alt="college marketing event" />
              </div>
              <div className="pics">
                <img src= {college05} alt="offline marketing ideas for college events" />
              </div>
              <div className="pics">
                <img src= {college06} alt="college event branding in bangalore" />
              </div>

              <h2>
            Sample Work
          </h2>
              
          </div>

        </div>

        <div className="services ad-containerGrey container-position">
          
        <div className='service-box'>
          <h2>
            Society Events
            <br />
            <small>
            Sometimes all individuals need is a fun and interesting society event over the weekend!!
            </small>
          </h2>          
            <p className='service-description p' >
            If you are organizing a large event for those outside of society, we can offer you with
            brochures and pamphlets, as well as stalls and banners for entertaining activities,
            all based on your budget.
            Customized bottles, backpacks, and team t-shirts for games are also offered.
            We have Canopies for fun activities & Food stalls too.

            </p>
            <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
            />
        </div>

          <div className="service-pics">
          <div className="pics">
                <img src= {society01} alt="society event marketing" />
              </div>
              <div className="pics">
                <img src= {society02} alt="society event marketing products" />
              </div>
              <div className="pics">
                <img src= {society03} alt="society event strategy" />
              </div>
              <div className="pics">
                <img src= {society04} alt="community event marketing" />
              </div>
              <div className="pics">
                <img src= {society05} alt="Association Event" />
              </div>
              <div className="pics">
                <img src= {society06} alt="importance of event branding" />
              </div>

              <h2>
            Sample Work
          </h2>
              
          </div>

        </div>

        <div className="services ad-containerWhite container-position">

        <div className='service-box'>
          <h2>
            Mall Events
            <br />
            <small>
            Arrange your next mall event to include demonstrations, live concerts, fashion shows, and
            product launches and more!!
            </small>
          </h2>          
            <p className='service-description p' >
            Raising the bar for your mall events with excellent hoardings and led banners.
            Your brand will undoubtedly draw clients if it is creatively promoted in a busy area like malls.
            Including eye-catching media walls, picture booths, and promotional items
            like personalized t-shirts, bags, umbrellas, and so forth.
            We can also provide promotors to sample your products inside the mall
            </p>
            <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
            />
        </div>

          <div className="service-pics">
          <div className="pics">
                <img src= {mall01} alt="mall branding" />
              </div>
              <div className="pics">
                <img src= {mall02} alt="mall branding ideas" />
              </div>
              <div className="pics">
                <img src= {mall03} alt="Shopping Mall Branding" />
              </div>
              <div className="pics">
                <img src= {mall04} alt="Advertising in Malls" />
              </div>
              <div className="pics">
                <img src= {mall05} alt="Mall Branding & Advertising" />
              </div>
              <div className="pics">
                <img src= {mall06} alt="mall ads" />
              </div>

              <h2>
            Sample Work
          </h2>
              
          </div>

        </div>

        <div className="services ad-containerGrey container-position">
          
        <div className='service-box'>
          <h2>
            Tech Park Events
            <br />
            <small>
            Best for IT organizations looking to networking opportunities!
            </small>
          </h2>          
            <p className='service-description p' >
            It is best to create a setting that will bring together tech companies of various kinds
            in one place. Your requirement for banners, display boards, and exhibition stalls can be fulfilled
            by us. We are also able to assist you with sample products. Big LED boards for big scale events.
            We can also provide customized bottles, caps, mugs, office bags, and other corporate gifts
            for the attendees, in addition to t-shirts with designs created just for organizing team.
            </p>
            <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
            />
        </div>

          <div className="service-pics">
              <div className="pics">
                <img src= {techpark01} alt="" />
              </div>
              <div className="pics">
                <img src= {techpark02} alt="" />
              </div>
              <div className="pics">
                <img src= {techpark03} alt="" />
              </div>
              <div className="pics">
                <img src= {techpark04} alt="" />
              </div>
              <div className="pics">
                <img src= {techpark05} alt="" />
              </div>
              <div className="pics">
                <img src= {techpark06} alt="" />
              </div>

              <h2>
            Sample Work
          </h2>
              
          </div>

        </div>

        <div className="services ad-containerWhite container-position">
          
        <div className='service-box'>
          <h2>
            Marathon Events
            <br />
            <small>
            Sweat, Smile, Repeat in your Marathon!!
            </small>
          </h2>          
            <p className='service-description p' >
            For the event, we can design unique flags, tabletops, banners, stalls, and enormous gate arches.
            Flyers and pamphlets for promotion are also available. Promote your event widely in order to
            draw in more marathon participants.
            </p>
            <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
            />
        </div>
        <div className="service-pics">
        <div className="pics">
                <img src= {marathon01} alt="marathon branding" />
              </div>
              <div className="pics">
                <img src= {marathon02} alt="marathon branding ideas" />
              </div>
              <div className="pics">
                <img src= {marathon03} alt="marathon brand clothing" />
              </div>
              <div className="pics">
                <img src= {marathon04} alt="marathon marketing cap" />
              </div>
              <div className="pics">
                <img src= {marathon05} alt="marathon marketing services marathon marketing company" />
              </div>
              <div className="pics">
                <img src= {marathon06} alt="marathon marketing" />
              </div>
              <h2>
            Sample Work
          </h2>
              
          </div>

        </div>
      </div>
    </motion.div>

    <div className='main-box'>
    <FAQ/>
    </div>
    </>
  )
}

export default Services
